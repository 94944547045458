import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Separator } from '@twilio-paste/separator';
import { Text } from '@twilio-paste/text';
import { Paragraph } from '@twilio-paste/paragraph';
import { Box } from '@twilio-paste/box';
import Changelog from '@twilio-paste/separator/CHANGELOG.md';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { SidebarCategoryRoutes } from '../../../constants';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/separator"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/separator/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Separator" categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/separator" storybookUrl="/?path=/story/components-separator--horizontal" abstractUrl="https://share.goabstract.com/85c81258-90b9-49c8-b34b-9418da5f2f00" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Separator`}</h3>
        <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
        <p>{`It can be used to create separation between large sections of page content, or between smaller parts of single UI controls such as menu items in a menu.`}</p>
        <p>{`It composes an `}<inlineCode parentName="p">{`<hr />`}</inlineCode>{` (`}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/html52/grouping-content.html#the-hr-element"
          }}>{`horizontal rule`}</a>{`) element with an explicit declaration of the `}<inlineCode parentName="p">{`separator`}</inlineCode>{` `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/wai-aria-1.1/#separator"
          }}>{`ARIA role`}</a>{`.`}</p>
        <p>{`It is heavily inspired by the `}<a parentName="p" {...{
            "href": "https://chakra-ui.com/divider"
          }}>{`Chakra UI`}</a>{` implementation.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Horizontal separator`}</h3>
        <p>{`Vertical separation can be created by using a horizontal separator. This is achieved by setting `}<inlineCode parentName="p">{`orientation="horizontal"`}</inlineCode>{`. A `}<inlineCode parentName="p">{`verticalSpacing`}</inlineCode>{` styling property is provided to let you to create space above and below the separator. `}<inlineCode parentName="p">{`verticalSpacing`}</inlineCode>{` will take any `}<a parentName="p" {...{
            "href": "/tokens#spacings"
          }}>{`Spacing Token`}</a>{` as its value.`}</p>
        <LivePreview scope={{
          Box,
          Separator
        }} language="jsx" mdxType="LivePreview">
  {`<Box backgroundColor="colorBackgroundBody" padding="space50">
  <Separator orientation="horizontal" verticalSpacing="space50" />
</Box>`}
        </LivePreview>
        <h3>{`Vertical separator`}</h3>
        <p>{`Horizontal separation can be created by using a vertical separator. This is achieved by setting `}<inlineCode parentName="p">{`orientation="vertical"`}</inlineCode>{`. A `}<inlineCode parentName="p">{`horizontalSpacing`}</inlineCode>{` styling property is provided to let you to create space on either side of the separator. `}<inlineCode parentName="p">{`horizontalSpacing`}</inlineCode>{` will take any `}<a parentName="p" {...{
            "href": "/tokens#spacings"
          }}>{`Spacing Token`}</a>{` as its value.`}</p>
        <LivePreview scope={{
          Separator,
          Text,
          Box
        }} language="jsx" mdxType="LivePreview">
  {`<Box backgroundColor="colorBackgroundBody" padding="space50" display="flex">
  <Text as="span">Option 1</Text>
  <Separator orientation="vertical" horizontalSpacing="space40" />
  <Text as="span">Option 2</Text>
  <Separator orientation="vertical" horizontalSpacing="space40" />
  <Text as="span">Option 3</Text>
  <Separator orientation="vertical" horizontalSpacing="space40" />
  <Text as="span">Option 4</Text>
  <Separator orientation="vertical" horizontalSpacing="space40" />
  <Text as="span">Option 5</Text>
</Box>`}
        </LivePreview>
        <h3>{`Spacing`}</h3>
        <p>{`The Separator comes with 2 spacing properties: `}<inlineCode parentName="p">{`horizontalSpacing`}</inlineCode>{` and `}<inlineCode parentName="p">{`verticalSpacing`}</inlineCode>{`. You can use these properties to create space between the Separator and the two pieces of UI it sits between.`}</p>
        <p>{`When used, the spacing properties create equal space in both directions along the orientation you choose.`}</p>
        <h4>{`Responsive spacing`}</h4>
        <p><inlineCode parentName="p">{`horizontalSpacing`}</inlineCode>{` and `}<inlineCode parentName="p">{`verticalSpacing`}</inlineCode>{` are responsive props. You can provide them an array of spacing values to change the spacing based on the screen sizes supported by your `}<a parentName="p" {...{
            "href": "/tokens/theme-package#theme-provider"
          }}>{`theme`}</a>{`.`}</p>
        <LivePreview scope={{
          Separator,
          Text,
          Box
        }} language="jsx" mdxType="LivePreview">
  {`<Box backgroundColor="colorBackgroundBody" padding="space50">
  <Box display="flex">
    <Text as="span">Option 1</Text>
    <Separator orientation="vertical" horizontalSpacing="space80" />
    <Text as="span">Option 2</Text>
  </Box>
  <Separator orientation="horizontal" verticalSpacing="space80" />
  <Box display="flex">
    <Text as="span">Option 1</Text>
    <Separator orientation="vertical" horizontalSpacing="space80" />
    <Text as="span">Option 2</Text>
  </Box>
</Box>`}
        </LivePreview>
        <h2>{`When to use the Separator`}</h2>
        <h3>{`Page content separation`}</h3>
        <p>{`A Separator can be used to create visual and semantic separation between two parts of a page or within page content. It can also be useful in creating hierarchy within a page.`}</p>
        <p>{`The most common use case closely reflects the HTML `}<inlineCode parentName="p">{`<hr />`}</inlineCode>{` (horizontal rule) element. The HTML specification describes a horizontal rule as representing a "thematic break" within a section of a page. You can use it as a transition to another topic or represent that 2 things in the same section are not directly related.`}</p>
        <p>{`It `}<strong parentName="p">{`should not`}</strong>{` be used as a substitute for styled semantic sectioning HTML. For example, borders on modal headers or sidebars.`}</p>
        <h3>{`Component separation`}</h3>
        <p>{`A Separator can also be used as an element inside components. For example, it can be used inside a menu to create separation between a set of menu items, effectly creating groups of menu items.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use to create separation between two elements" preview={false} mdxType="Do" />
  <Dont title="Don't" body="Don't use as a substitute for styled semantic sectioning HTML, like borders on headers or sidebars" preview={false} mdxType="Dont" />
        </DoDont>
        <hr></hr>
        <h2>{`Usage guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/separator - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Separator} from '@twilio-paste/separator';

const Component = () => <Separator orientation="horizontal" />;
`}</code></pre>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`orientation?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'horizontal', 'vertical'`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Separator direction`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`horizontalSpacing?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ResponsiveValue<`}<a parentName="td" {...{
                  "href": "/tokens/#spacings"
                }}>{`Spacing`}</a>{`>`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Space left and right of the separator`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`verticalSpacing?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ResponsiveValue<`}<a parentName="td" {...{
                  "href": "/tokens/#spacings"
                }}>{`Spacing`}</a>{`>`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Space top and bottom of the separator`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      